import React from "react";
import "./App.css";
// @ts-ignore
import { Fade } from "react-reveal";
import {
  Button,
  Modal,
  useMantineTheme,
  List,
  ThemeIcon,
  Avatar,
} from "@mantine/core";
// @ts-ignore
import TypeAnimation from "react-type-animation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEnvelopeOpenText, faFileLines } from "@fortawesome/free-solid-svg-icons";

function App() {
  const [isImageModalOpened, setIsImageModalOpened] = React.useState(false);
  const [modalImageURL, setModalImageURL] = React.useState("");

  const theme = useMantineTheme();
  theme.colorScheme = "dark";

  return (
    <div className="scroll-smooth height-[100vh]">
      <Modal
        size={1000}
        opened={isImageModalOpened}
        onClose={() => setIsImageModalOpened(false)}
      >
        <img src={modalImageURL} alt="" />
      </Modal>
      <div className="fixed grid place-items-center h-full w-full overflow-auto">
        <TypeAnimation
          className="text-[7vw] text-white"
          cursor={true}
          sequence={["Portfolio", 2000, "Portfolio von Luca", 2000]}
          wrapper="p"
          repeat={Infinity}
        />
        <div className="m-32">
          <Avatar
            size="xl"
            radius="xl"
            className="scale-[3] my-14"
            src="https://cdn.discordapp.com/attachments/762787637210644481/970368578358493224/20220501_162514775_iOS.jpg"
            alt=""
          />
        </div>
        <div className="ml-4">
          <Button className="bg-white hover:bg-white text-black  transition-all mr-12">
            <FontAwesomeIcon className="mr-2" icon={faFileLines} />
            Lebenslauf
          </Button>
          <Button className="bg-white hover:bg-white text-black  transition-all ml-2">
            <FontAwesomeIcon className="mr-2" icon={faEnvelopeOpenText} />
            Bewerbung
          </Button>
        </div>
        <div className="grid grid-cols-2 p-14 max-w-5xl">
          <Fade left>
            <img
              className="mr-2 my-2 rounded-xl border-violet-500 hover:border-4"
              src="https://cdn.discordapp.com/attachments/762787637210644481/963830602782617630/2.png"
              onClick={() => {
                setModalImageURL(
                  "https://cdn.discordapp.com/attachments/762787637210644481/963829191730683934/FirdGT40_Poster.jpg"
                );
                setIsImageModalOpened(true);
              }}
              alt=""
            />
          </Fade>
          <Fade right>
            <img
              className="ml-2 my-2 rounded-xl border-violet-500 hover:border-4"
              src="https://cdn.discordapp.com/attachments/762787637210644481/963834425781649488/Unbenannt-1.jpg"
              onClick={() => {
                setModalImageURL(
                  "https://cdn.discordapp.com/attachments/762787637210644481/963834425781649488/Unbenannt-1.jpg"
                );
                setIsImageModalOpened(true);
              }}
              alt=""
            />
          </Fade>
          <Fade left>
            <img
              className="mr-2 my-2 rounded-xl border-violet-500 hover:border-4"
              src="https://cdn.discordapp.com/attachments/762787637210644481/963831576767115304/pfpportfolio.png"
              onClick={() => {
                setModalImageURL(
                  "https://cdn.discordapp.com/attachments/762787637210644481/963831119986429963/lurex-pfp.jpg"
                );
                setIsImageModalOpened(true);
              }}
              alt=""
            />
          </Fade>
          <Fade right>
            <img
              className="ml-2 my-2 rounded-xl border-violet-500 hover:border-4"
              src="https://media.discordapp.net/attachments/935083013870604288/940715246643998780/Unbenannt-1.jpg"
              onClick={() => {
                setModalImageURL(
                  "https://media.discordapp.net/attachments/935083013870604288/940715246643998780/Unbenannt-1.jpg"
                );
                setIsImageModalOpened(true);
              }}
              alt=""
            />
          </Fade>
        </div>
        <Fade bottom>
          <div className="bg-[#191a24] mb-24 grid place-items-center bg-red- rounded-xl p-8 shadow-xl">
            <div className="p-4 mb-14">
              <p className="text-white text-5xl">Skills</p>
            </div>
            <List
              spacing="xs"
              size="sm"
              center
              icon={
                <ThemeIcon color="teal" size={24} radius="xl">
                  <FontAwesomeIcon icon={faCheck} />
                </ThemeIcon>
              }
            >
              <List.Item>Kreativität &  Abwechlungsreiche Ideen</List.Item>
              <List.Item>Schulische sowie private Erfahrung im Bereich Mediengestalltung</List.Item>
              <List.Item>Vortgeschrittenes Wissen in Photoshop, Illustrator und InDesign</List.Item>
              <List.Item>Gute Kenntnisse in der Aufbereitung von z.B PowerPoints</List.Item>
            </List>
          </div>
        </Fade>

      </div>
    </div>
  );
}

export default App;
